/* Side Menu */

#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: $primary;
  border-left: 1px solid fade-out($white, 0.9);
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
}

.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: $white;
  padding: 15px;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, .2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  font-size: 1.2rem;
  background: fade-out($gray-800, .9);
  height: 80px;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.sidebar-nav > .sidebar-brand a {
  color: $white;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

#sidebar-wrapper.active {
  right: 250px;
  width: 250px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;

  width: 50px;
  height: 50px;

  text-align: center;

  color: $white;
  background: fade-out($gray-800, .5);

  line-height: 50px;

  z-index: 999;
  &:focus,
  &:hover {
    color: $white;
  }
  &:hover {
    background: $gray-800;
  }
  &.active {
    // background-color: $white;
    // color: $gray-900;
  }
}
