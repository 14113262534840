.headpage {
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: linear-gradient(45deg, #2e4998, #4a75b8) no-repeat center center;
  background-size: cover;
  h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 992px) {
    h1 {
      font-size: 5.5rem;
    }
  }
}

.social-link {
  display: block;
  height: 4rem;
  width: 4rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color: darken($primary, 10%);
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  &:hover {
    background-color: $primary;
  }
}

