
div.sponsor {
  border: 1px solid #ba9106;
  height: 160px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
}

div.sponsor > img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

