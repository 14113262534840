body,
html {
  width: 100%;
  height: 100%;
}

body {
  @include body-font;
}

// Custom Button Size
.btn-xl {
  padding: 1.25rem 2.5rem;
}

// Content Section
.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.content-section-heading {
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.text-faded {
  color: fade-out($white, 0.3);
}

/* Map */

.map {
  height: 30rem;
}

@media(max-width: 992px) {
  .map {
    height: 75%;
  }
}

.map iframe {
  pointer-events: none;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;

  display: none;

  width: 50px;
  height: 50px;

  text-align: center;

  color: white;
  background: fade-out($gray-800, .5);

  line-height: 45px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}

.primary-title {
  color: $primary;
}

.service-icon:hover {
  color: white !important;
  background: darken($primary, 10%);
  text-decoration: none;
  cursor: pointer;
}

.badge-vcp{
  color: white;
  background-color: $primary;
}

.hidden{
  visibility:hidden;
}

.show-on-hover-parent:hover .hidden{
  visibility:visible;
}

.trooper-section {
  background: linear-gradient(45deg,$secondary,darken($secondary, 5%));
  padding: 20px 20px;
  text-align: center;
}

a.lid-worden-knop {
  background: rgb(241, 137, 4);
  position: absolute;
  top: 15px;
  left: 15px;
  height: 50px;
  padding: 10px 20px;
  z-index: 2000;
  border-radius: 5px;
  font-size: 20px;
  color: whitesmoke !important;
}

a.lid-worden-knop:hover {
  background: rgba(241, 137, 4, 0.71);
  text-decoration: none;
}

.lid-worden-bubble {
  width: 30%;
  margin-left: 60%;
  margin-right: 40%;
}

.lid-worden-bubble:hover{
  filter: brightness(0) invert(1) opacity(60%);
}

.popover-header {
  background: none !important;
}

b.functie-naam {
  color: $secondary;
  padding-top: 10px;
}

span.functie-blok {
  padding: 5px;
  display: block;
}

.popover.popover-content {
  padding: 0 !important;
}

.popover-body a {
  color: #ced4da;
}

.popover-body a:hover {
  color: white;
}

.popover.popover-content .popover-header {
  text-transform: capitalize !important;
  font-size: 17px !important;
}
