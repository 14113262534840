// Callout
.callout {
  padding: 15rem 0;
  background: linear-gradient(90deg, fade-out($white, 0.9) 0%, fade-out($white, 0.9) 100%), url('../assets/backgrounds/maxresdefault.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    display: block;
    max-width: 30rem;
  }
}
