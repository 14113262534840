 /**
  * ----------------------------------------
  * animation buzz-out-on-hover
  * ----------------------------------------
 */

 .buzz-out-on-hover {
   -webkit-transform: perspective(0px) translateZ(0);
   transform: perspective(0px) translateZ(0);
 }

 .buzz-out-on-hover:hover, .buzz-out-on-hover:focus, .buzz-out-on-hover:active {
   -webkit-animation-name: buzz-out-on-hover;
   animation-name: buzz-out-on-hover;

   -webkit-animation-duration: 0.75s;
   animation-duration: 0.75s;

   -webkit-animation-timing-function: linear;
   animation-timing-function: linear;

   -webkit-animation-iteration-count: 1;
   animation-iteration-count: 1;
 }

@-webkit-keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0);
    transform: translateX(0px) rotate(0);
  }
}
@keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0);
    transform: translateX(0px) rotate(0);
  }
}




 /**
  * ----------------------------------------
  * animation slide-bl
  * ----------------------------------------
 */

 .slide-bl{
 	-webkit-animation: slide-bl 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	        animation: slide-bl 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 @-webkit-keyframes slide-bl {
   0% {
     -webkit-transform: translateY(-100px) translateX(100px);
             transform: translateY(-100px) translateX(100px);
   }
   100% {
     -webkit-transform: translateY(0) translateX(0);
             transform: translateY(0) translateX(0);
   }
 }
 @keyframes slide-bl {
   0% {
     -webkit-transform: translateY(-100px) translateX(100px);
             transform: translateY(-100px) translateX(100px);
   }
   100% {
       -webkit-transform: translateY(0) translateX(0);
               transform: translateY(0) translateX(0);
   }
 }


  /**
   * ----------------------------------------
   * animation fade-on-hover
   * ----------------------------------------
  */

 .fade-on-hover {
   opacity: 1;
   transition: opacity .5s ease-in-out;
   -moz-transition: opacity .5s ease-in-out;
   -webkit-transition: opacity .5s ease-in-out;
 }

 .fade-on-hover:hover {
   opacity: 0.5;
 }


